@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
}

.notices {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
}

.home {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
}
.course {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
}

.news-events {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
}
.create-notice {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
}

.create-course {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
}



.manage-notice {
  display: flex;
  margin-top: 10px;
  align-items: center;
  justify-content: center;
}


