/*footer*/
.col_white_amrc {
    color: #FFF;
}

footer {
    width: 100%;
    background-color: #292929;
    min-height: 50px;
    padding: 10px 0px 25px 0px;
}

.pt2 {
    padding-top: 40px;
    margin-bottom: 20px;
}

footer p {
    font-size: 20px;
    color: #CCC;
    padding-bottom: 0px;
    margin-bottom: 8px;
    text-align: center;

}

.mb10 {
    padding-bottom: 15px;
}


.bottom_border {
    border-bottom: 1px solid #323f45;
    padding-bottom: 20px;
}
