#navbar {
  background-color: rgb(41, 41, 41);
  height: 80px;
  display: flex;
  justify-content: start;
  align-items: center;
}


.menu-bars {
  margin-left: 2rem;
  font-size: 2rem;
  background: none;
}

.nav-menu {
  background-color: rgb(41, 41, 41);
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 850ms ease-in-out;
  z-index: 99999;

}

.nav-menu.active {
  left: 0;
  transition: 550ms;
  z-index: 99999;

}


.nav-text {
  display: flex;
  justify-content: start;
  align-items: center;
  padding:  8px 0px 8px 16px;
  list-style: none;
  height: 60px;
}


.nav-text a {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  border-radius:6px;
}


.nav-text a:hover {
  background-image:linear-gradient(0deg,#4792e2,#65abf5) ;
  color: white;
  font-weight: bold;
}


.nav-menu-items {
  width: 100%;
}


.navbar-toggle {
  background-color: rgb(41, 41, 41);
  width: 100%;
  height: 80px ;
  display: flex;
  justify-content: start;
  align-items: center;
}

span {
  margin-left: 10px;
}